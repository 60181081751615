import React, { Component } from "react";

import profileImage from "./assets/images/profile.jpg";
import { ReactComponent as GithubImage } from "./assets/images/github.svg";
import { ReactComponent as LinkedinImage } from "./assets/images/linkedin.svg";
import { ReactComponent as TwitterImage } from "./assets/images/twitter.svg";
import { ReactComponent as EmailImage } from "./assets/images/email.svg";

class App extends Component {
  render() {
    return (
      <div className="w-100 h-100 flex tc items-center justify-center flex-column">
        <div className="profile-image mb2 br-100 overflow-hidden">
          <img className="w-100 h-100" src={profileImage} alt="Profile" />
        </div>
        <div className="mb2">Aaron Ortbals</div>
        <div className="sub-title ao-gray mb2 f6_5">
          Managing Partner, Head of Engineering @{" "}
          <a href="https://postlight.com/">Postlight</a>
        </div>
        <div className="mt1 flex align-center">
          <a
            name="Github"
            className="db mr2"
            style={{ width: "16px" }}
            href="https://github.com/aortbals"
          >
            <GithubImage
              className="fill-gray link-hover w-100 h-100"
              alt="Github"
            />
          </a>
          <a
            name="LinkedIn"
            className="db"
            style={{ width: "15px" }}
            href="https://www.linkedin.com/in/aaronortbals/"
          >
            <LinkedinImage
              className="fill-gray link-hover w-100 h-100"
              alt="LinkedIn"
            />
          </a>
          <a
            name="Twitter"
            className="db ml2"
            style={{ width: "16px" }}
            href="https://twitter.com/aaronortbals"
          >
            <TwitterImage
              className="fill-gray link-hover w-100 h-100"
              alt="Twitter"
            />
          </a>
          <a
            name="Email"
            className="db ml2"
            style={{ width: "16px" }}
            href="mailto:me@aaronortbals.com?subject=Hello%21"
          >
            <EmailImage
              className="fill-gray link-hover w-100 h-100"
              alt="Contact"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default App;
